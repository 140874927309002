import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import cardPaymentIcon from "../../assets/checkout/cards.svg";
import downArrow from "../../assets/checkout/arrow-square-down.svg";
import cashPaymentIcon from "../../assets/checkout/moneys.svg";

import tabbiIcon from "../../assets/payment/tabby.png";
import tamaraIcon from "../../assets/payment/tamara.png";
import unCheckIcon from "../../assets/checkout/tick-circle.svg";
import checkIcon from "../../assets/checkout/vuesax-linear-tick-circle.svg";
import CustomModal from "../modal/CustomModal";

import PromoCode from "../PromoCode";
import {
  cartItemsSummary,
  errorRequestHandel,
  formatDecimal,
  getCurrencyMultiplier,
} from "../../utils/helperFile";
import { checkTabby, checkTamara } from "../../https/ordersRequests";
import { toast } from "react-toastify";
import {
  _setCountry,
  _toggleLoginModal,
  _toggleOverylay,
} from "../../redux/actions/settingsAction";
import { _login } from "../../redux/actions/authentication";
import { _clearCartItems, _getAllCartItems } from "../../redux/actions/product";
import { listAllCartItems } from "../../https/cartRequests";
import ConfirmationModal from "../product-related/confirmation/ConfirmationModal";
import useWindowSize from "../../utils/hooks/useWindowSize";
import CartLinksOptions from "./CartLinksOptions";
import { paymentImplementation } from "../../https/paymentImplementation";

import infoIcon from "../../assets/info.png";
import CustomProfileModal from "../custom-profile-modal/CustomProfileModal";
import {
  trackAddPaymentInfo,
  ttqTrackAddPaymentInfo,
} from "../../utils/analyticsEvents";
import SenseOfUrgency from "../sale-and-view/SenseOfUrgency";
import { _resetTimer } from "../../redux/actions/urgencyActions";

function CartShippingOptions({ hide, cartItems, address_id }) {
  const userToken = localStorage.getItem("token");
  const { width } = useWindowSize();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(true);
  const [open, setOpen] = useState(false);
  const [cardPayment, setCardPayment] = useState(true);
  const [cashPayment, setCashPayment] = useState(false);
  const [standardShipping, setStandardShipping] = useState(true);
  const [sameDayShipping, setSameDayShipping] = useState(false);
  const [isTabbySelect, setisTabbySelect] = useState(false);
  const [isTammaraSelect, setisTammaraSelect] = useState(false);
  const _selectedCountry = useSelector(
    (state) => state._settings.selectedCountry
  );
  const [selectedCountry, setSelectedCountry] = useState(
    _selectedCountry ?? ""
  );
  const _countryLists = useSelector((state) => state._general.countryList);
  const userInformation = useSelector((state) => state._auth.user);

  const [addressId, setAddressId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [isAllFields, setisAllFields] = useState(false);
  const [isFromPayment, setisFromPayment] = useState(false);
  const [selectedCountryId, setSelectedCountryId] = useState(
    _selectedCountry ? _selectedCountry?.id : ""
  );

  const [eidHolidaysEnabled, setEidHolidaysEnabled] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const endDate = new Date("2024-06-20");
    if (currentDate <= endDate) {
      setEidHolidaysEnabled(true);
    }
  }, []);

  const [isUserEligibleForTabby, setIsUserEligibleForTabby] = useState(false);

  // Use the cartItemsSummary function to calculate subtotal and total amount

  const [values, setValues] = useState({
    currency: "",
    sub_total: "",
    discount: "",
    total_amount: "",
    payment_type: "",
    delivery_types: "",
    address_id: address_id,
    item: [],
  });

  const exchangeRates = useSelector((state) => state._general.exchangeRates);
  const countryDeliveryTaxes = useSelector(
    (state) => state._general.countryDeliveryTaxes
  );
  const [selectedCountryDeliveryTaxes, setSelectedCountryDeliveryTaxes] =
    useState(null);

  const [discountedAmount, setDiscountedAmount] = useState({
    value: 0,
    type: "Percentage",
  });
  const [rateMultiplier, setRateMultiplier] = useState(1);
  useEffect(() => {
    setRateMultiplier(
      getCurrencyMultiplier(exchangeRates, _selectedCountry?.currency)
    );
  }, [_selectedCountry, selectedCountry, exchangeRates]);

  const _countryDeliveryTaxes = countryDeliveryTaxes?.find(
    (item) => item.country.id === _selectedCountry?.id
  );

  const product_detail = useSelector((state) => state.products.product_detail);
  // const [showAnnouncement, setShowAnnouncement] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setisFromPayment(true);
    }, 1000);
  }, []);
  useEffect(() => {
    setSelectedCountryDeliveryTaxes(_countryDeliveryTaxes);
  }, [_selectedCountry, selectedCountry, countryDeliveryTaxes]);

  useEffect(() => {
    getCartItemsFromServer();
  }, []);
  const getCartItemsFromServer = async () => {
    try {
      const response = await listAllCartItems(userToken);
      if (response.status === 200) {
        dispatch(_getAllCartItems(response.data.data));
      }
    } catch (error) {}
  };

  const toggleCard = () => {
    setIsExpanded(!isExpanded);
  };

  const {
    subTotal,
    totalAmount,
    discountAmount,
    standardDeliveryCharges,
    sameDayDeliveryCharges,
    codFee,
  } = cartItemsSummary(
    sameDayShipping,
    cashPayment,
    discountedAmount,
    cartItems,
    selectedCountryDeliveryTaxes,
    rateMultiplier
  );

  const onCheckOutClick = async () => {
    if (
      userInformation &&
      (userInformation?.phone == "" || userInformation?.phone_code == "")
    ) {
      toast.warning("Please reload the page to complete your profile.");
      return;
    }
    const session_id = localStorage.getItem("session_id");
    let items = [];
    items = cartItems?.map((item) => ({
      product_id: item.productId,
      variant_id: item.variantId,
      quantity: item.quantity,
      price: formatDecimal(
        (item.onSale ? item.salePrice : item.regularPrice) * rateMultiplier
      ), // Replace with the actual price
    }));

    let payload = {
      data: {
        promo_code: promoCode,
        session_id: session_id,
        currency: _selectedCountry?.currency,
        sub_total: subTotal,
        cod_fee: codFee,
        discount: discountAmount,
        total_amount: totalAmount,
        payment_type: cardPayment
          ? "ONLINE"
          : isTabbySelect
          ? "TABBY"
          : isTammaraSelect
          ? "TAMARA"
          : "COD",
        delivery_types: standardShipping ? "Standard Shipping" : "Same Day",
        address_id: address_id,
        delivery_charges: standardShipping
          ? standardDeliveryCharges
          : sameDayDeliveryCharges,
        items: items,
        // address: userAddress ? { ...userAddress, country: userAddress?.country?.id } : manuallyAddAddress,
      },
    };
    try {
      if (cartItems?.length === 0) {
        toast.warning("Please add items to cart");
        return;
      }
      setisLoading(true);
      if (isTabbySelect) {
        // check if allow
        const tabbyResponse = await checkTabby(payload);
        trackAddPaymentInfo(
          selectedCountry?.currency,
          product_detail,
          promoCode,
          values?.payment_type,
          rateMultiplier
        );
        ttqTrackAddPaymentInfo(product_detail);
        if (tabbyResponse.status !== 200) {
          setisLoading(false);
          toast.warning(
            tabbyResponse.data.errorMessage ?? "Taaby not available"
          );
          return;
        }
      }
      if (isTammaraSelect) {
        // check if allow
        const tamaraResponse = await checkTamara(payload);
        trackAddPaymentInfo(
          selectedCountry?.currency,
          product_detail,
          promoCode,
          values?.payment_type,
          rateMultiplier
        );
        ttqTrackAddPaymentInfo(product_detail);
        if (tamaraResponse.status !== 200) {
          setisLoading(false);
          toast.warning(
            tamaraResponse.data.errorMessage ?? "Tamara  not available"
          );
          return;
        }
      }

      const response = await paymentImplementation(payload);
      if (response.status === 200) {
        //  const { jwt, user } = response.data?.data;
        if (userToken === "" || userToken === null || userToken == undefined) {
          dispatch(_login(response.data?.data.user_account));
        }
        dispatch(_clearCartItems());
        dispatch(_resetTimer());
        const order_id = response?.data?.data?.identifier;
        setOrderId(order_id);

        setisLoading(false);
        if (cashPayment) {
          trackAddPaymentInfo(
            selectedCountry?.currency,
            product_detail,
            promoCode,
            values?.payment_type,
            rateMultiplier
          );
          ttqTrackAddPaymentInfo(product_detail);
          // setOpen(true);
          navigate(`/order-confirmation/${order_id}`);
        } else {
          // return;
          trackAddPaymentInfo(
            selectedCountry?.currency,
            product_detail,
            promoCode,
            values?.payment_type,
            rateMultiplier
          );
          ttqTrackAddPaymentInfo(product_detail);
          const paymenUrl = response?.data?.data?.url;
          window.history.pushState({}, "", "");
          window.location.href = paymenUrl;
        }
      }
    } catch (error) {
      setisLoading(false);
      if (error?.response?.data?.errorCode === "not_available") {
        setIsUserEligibleForTabby(true);
        setCardPayment(true);
        setCashPayment(false);
        setisTabbySelect(false);
        setisTammaraSelect(false);
      }
      errorRequestHandel({ error: error });
    }
  };

  const onApplyCoupon = (value, promoCodeVal) => {
    setDiscountedAmount(value);
    setPromoCode(promoCodeVal);
  };

  useEffect(() => {
    setCardPayment(true);
    setisTabbySelect(false);
    setisTammaraSelect(false);
  }, [_selectedCountry]);

  const buttonRef = useRef(null);

  const handleIconClick = () => {
    // Forward the click event from the icon to the button
    // This will trigger the button's click event, which may open the modal
    buttonRef.current.click();
  };

  return (
    <div className={`_paymentContent ${hide}`}>
      <div className="container">
        <div className="row gx-5 ">
          <div className="col-md-6 mb-4 mb-md-0 ">
            {/* <SenseOfUrgency /> */}

            {/* Left side: Form input fields */}

            {selectedCountry === "" ? (
              <div className="card no-border-radius">
                <div className="card-body">
                  <p className="card-text">
                    Add address to see shipping options.
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div className="d-flex justify-content-between align-items-center col-md-6">
                  <p className="checkout__create__Account">Shipping Options</p>
                </div>
                <div className="checkout__card">
                  <div className="card no-border-radius">
                    <div className="card-body d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img src={cardPaymentIcon} alt="Card Payment" />
                        <div>
                          <p className="card-title mb-0 ms-4">
                            Standard Shipping
                          </p>
                          <p className="mb-0 ms-4 shipping_subtitle">
                            {subTotal <
                            selectedCountryDeliveryTaxes?.free_shipping_threshold *
                              rateMultiplier ? (
                              <>
                                {_selectedCountry?.currency}{" "}
                                {standardDeliveryCharges}
                              </>
                            ) : (
                              <>Free</>
                            )}
                          </p>
                          {selectedCountryDeliveryTaxes?.estimated_delivery_time !==
                            null && (
                            <>
                              <p className="mb-0 ms-4 shipping_subtitle">
                                Estimated Delivery Time:{" "}
                                {
                                  selectedCountryDeliveryTaxes?.estimated_delivery_time
                                }
                              </p>

                              <p className="mb-0 ms-4 shipping_subtitle small">
                                {eidHolidaysEnabled
                                  ? "Due to Eid Al Adha Holidays, There might be some delays in the delivery"
                                  : "Excluding Weekends and Public Holidays"}
                              </p>
                              {/* <p className="mb-0 ms-4 shipping_subtitle small">
                                DUE TO NEW YEAR HOLIDAY, DELIVERIES MAY
                                EXPERIENCE SLIGHT DELAYS.
                              </p> */}
                            </>
                          )}
                        </div>
                      </div>

                      <div
                        onClick={() => {
                          setStandardShipping(true);
                          setSameDayShipping(false);
                        }}
                      >
                        <img
                          src={standardShipping ? checkIcon : unCheckIcon}
                          alt="Check Icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {selectedCountryDeliveryTaxes &&
                  !eidHolidaysEnabled &&
                  selectedCountryDeliveryTaxes.same_day_delivery_fee !== 0 &&
                  selectedCountryDeliveryTaxes.same_day_delivery_fee !== null &&
                  !cashPayment && (
                    <div className="checkout__card">
                      <div className="card no-border-radius">
                        <div className="card-body d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <img src={cashPaymentIcon} alt="Card Payment" />
                            <div>
                              <p className="card-title mb-0 ms-4">
                                Same Day Shipping
                              </p>
                              <p className="mb-0 ms-4 shipping_subtitle">
                                {subTotal <
                                selectedCountryDeliveryTaxes?.free_shipping_threshold *
                                  rateMultiplier ? (
                                  <>
                                    {_selectedCountry?.currency}{" "}
                                    {sameDayDeliveryCharges}
                                  </>
                                ) : (
                                  <>Free</>
                                )}
                              </p>
                              <p className="mb-0 ms-4 shipping_subtitle">
                                Only orders placed before 12 PM (Available only
                                in Dubai).
                              </p>
                              <p className="mb-0 ms-4 shipping_subtitle">
                                Same-day delivery for card or online payments
                                only. Not available for cash payments.
                              </p>
                              <p className="mb-0 ms-4 shipping_subtitle small">
                                Excluding Weekends and Public Holidays
                              </p>
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setStandardShipping(false);
                              setSameDayShipping(true);
                            }}
                          >
                            <img
                              src={sameDayShipping ? checkIcon : unCheckIcon}
                              alt="Check Icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}
            {selectedCountryDeliveryTaxes?.note !== null && (
              <div className="mb-0 ms-0 shipping_subtitle">
                <p>{selectedCountryDeliveryTaxes?.note}</p>
              </div>
            )}

            <div className="shipping-policy">
              <p>
                For details view our{" "}
                <Link
                  to={"/shipping-and-delivery"}
                  className="blue-bold"
                  target="_blank"
                >
                  Shipping Policy
                </Link>
              </p>
            </div>

            <div className="checkout__card">
              <div className="card no-border-radius">
                <div className="card-body d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <img src={cardPaymentIcon} alt="Card Payment" />
                    <div>
                      <p className="card-title mb-0 ms-4">Card Payment</p>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setCardPayment(true);
                      setCashPayment(false);
                      setisTabbySelect(false);
                      setisTammaraSelect(false);
                      setValues({ ...values, payment_type: "card" });
                    }}
                  >
                    <img
                      src={cardPayment ? checkIcon : unCheckIcon}
                      alt="Check Icon"
                    />
                  </div>
                </div>
              </div>
            </div>

            {selectedCountryDeliveryTaxes &&
              // selectedCountryDeliveryTaxes.cod_fee !== 0 &&
              selectedCountryDeliveryTaxes.cod_fee !== null &&
              !sameDayShipping && (
                <div className="checkout__card">
                  <div className="card no-border-radius">
                    <div className="card-body d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img src={cashPaymentIcon} alt="Card Payment" />

                        <div>
                          <p className="card-title mb-0 ms-4">
                            Cash on Delivery
                          </p>
                          {selectedCountryDeliveryTaxes.cod_fee !== 0 && (
                            <span className="card-title mb-0 ms-4">
                              <>
                                Additional {_selectedCountry?.currency}{" "}
                                {formatDecimal(
                                  selectedCountryDeliveryTaxes?.cod_fee *
                                    rateMultiplier
                                )}
                              </>
                            </span>
                          )}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setCashPayment(true);
                          setCardPayment(false);
                          setisTabbySelect(false);
                          setisTammaraSelect(false);
                          setValues({ ...values, payment_type: "cash" });
                          setStandardShipping(true);
                          setSameDayShipping(false);
                        }}
                      >
                        <img
                          src={cashPayment ? checkIcon : unCheckIcon}
                          alt="Check Icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {!isUserEligibleForTabby && (
              <>
                {_selectedCountry?.has_tabby_support && (
                  <div className="checkout__card">
                    <div className="card no-border-radius">
                      <div className="card-body d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img src={tabbiIcon} alt="Card Payment" />
                          <div
                            style={{
                              textAlign: "left",
                              paddingLeft: 18,
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <p className="card-title mb-1">
                                Pay in 4. No interest, no fees.
                              </p>

                              <button
                                ref={buttonRef}
                                type="button"
                                data-tabby-info="installments"
                                data-tabby-price={totalAmount}
                                data-tabby-currency={_selectedCountry?.currency}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                }}
                              >
                                <img
                                  src={infoIcon}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    marginBottom: 5,
                                    objectFit: "contain",
                                  }}
                                  onClick={handleIconClick}
                                />
                              </button>
                            </div>
                            <span className="card-title mb-0">
                              Use any card.
                              {/* Pay a minimum of <b>{_selectedCountry?.currency}</b>{" "}
                          <b>{formatDecimal(totalAmount / 4)}</b> now, and the
                          rest over time - no hidden fees, no interest. */}
                            </span>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setisTammaraSelect(false);

                            setisTabbySelect(true);
                            setCashPayment(false);
                            setCardPayment(false);
                            setValues({ ...values, payment_type: "tabby" });
                          }}
                        >
                          <img
                            src={isTabbySelect ? checkIcon : unCheckIcon}
                            alt="Check Icon"
                            style={{ marginLeft: 10 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {_selectedCountry?.has_tamara_support && (
              <div className="checkout__card">
                <div className="card no-border-radius">
                  <div className="card-body d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={tamaraIcon}
                        alt="Card Payment"
                        style={{ objectFit: "contain" }}
                      />
                      <div
                        style={{
                          textAlign: "left",
                          paddingLeft: 18,
                        }}
                      >
                        <p className="card-title mb-1">Pay with Tamara</p>
                        <span className="card-title mb-0">
                          Pay a minimum of <b>{_selectedCountry?.currency}</b>{" "}
                          <b>{formatDecimal(totalAmount / 4)}</b> now, and the
                          rest over time - no hidden fees, no interest.
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setisTammaraSelect(true);
                        setisTabbySelect(false);
                        setCashPayment(false);
                        setCardPayment(false);
                        setValues({ ...values, payment_type: "tamara" });
                      }}
                    >
                      <img
                        src={isTammaraSelect ? checkIcon : unCheckIcon}
                        alt="Check Icon"
                        style={{ marginLeft: 10 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <CartLinksOptions />
          </div>
          <div className="col-md-6 custom_cart_rightside_content">
            {/* Right side: Other information */}
            <br />

            <div
              className={`card_expand  ${isExpanded ? "expanded" : ""}`}
              style={{ border: 0 }}
            >
              <div className="card-header" onClick={toggleCard}>
                <span className="card-title">Summary</span>
                <img
                  src={downArrow}
                  alt="Expand/Collapse Icon"
                  className={`arrow-icon ${isExpanded ? "expanded" : ""}`}
                />
              </div>
              {/* <div className="free__deliveryfee_container">
                <FreeDeliveryInfo />
              </div> */}
              {isExpanded && (
                <div className="card-body">
                  <div className="card__expand_body">
                    <PromoCode
                      onApplyCoupon={onApplyCoupon}
                      subTotal={subTotal}
                      rateMultiplier={rateMultiplier}
                      currency={_selectedCountry?.currency}
                    />
                    <div className="checkout_summary__item">
                      <span>Sub Total</span>
                      <span>
                        {_selectedCountry?.currency}{" "}
                        {formatDecimal(Number(subTotal) + Number(codFee))}
                      </span>
                    </div>
                    <div className="checkout_summary__item">
                      <span>Delivery Charges</span>
                      <span>
                        {subTotal <
                        selectedCountryDeliveryTaxes?.free_shipping_threshold *
                          rateMultiplier ? (
                          <>
                            {_selectedCountry?.currency}{" "}
                            {standardShipping
                              ? standardDeliveryCharges
                              : sameDayDeliveryCharges}
                          </>
                        ) : (
                          <>Free</>
                        )}
                      </span>
                    </div>
                    <div className="checkout_summary__item">
                      <span>Promo Discount</span>
                      <span>
                        {_selectedCountry?.currency} {discountAmount}
                      </span>
                    </div>
                    {(isTabbySelect || isTammaraSelect) && (
                      <div className="checkout_summary__item">
                        <span>Total</span>
                        <span>
                          {_selectedCountry?.currency} {totalAmount}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!isTabbySelect && !isTammaraSelect && (
                <div className="checkout_summary__item_total">
                  <span className="checkout__total">Total</span>
                  <span className="checkout__total_amount">
                    {_selectedCountry?.currency} {totalAmount}
                  </span>
                </div>
              )}
              {(isTabbySelect || isTammaraSelect) && (
                <div className="checkout_summary__item_total">
                  <span className="checkout__total">Pay Now</span>
                  <span className="checkout__total_amount">
                    {_selectedCountry?.currency}{" "}
                    {formatDecimal(totalAmount / 4)}
                  </span>
                </div>
              )}
              {isAllFields ? (
                <div className="checkout_confirm__button_disabled">
                  <p>CONTINUE TO PAYMENT</p>
                </div>
              ) : (
                <div
                  className="checkout_confirm__button"
                  onClick={onCheckOutClick}
                >
                  {!isLoading ? (
                    <p>CONTINUE TO PAYMENT</p>
                  ) : (
                    <div
                      class="spinner-border"
                      role="status"
                      style={{ color: "white" }}
                    >
                      <span className="sr-only"></span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={open}
        onCloseModal={() => setOpen(false)}
        showCloseIcon={false}
      >
        <ConfirmationModal orderId={orderId} />
      </CustomModal>
      <CustomProfileModal />
    </div>
  );
}
export default CartShippingOptions;
